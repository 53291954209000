import React from 'react';
import Hello from './Hello.js';
import Menu from './Menu.jsx';
import './App.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

const App = () => (
  <div>
    <Menu />
    <hr />
    <div className="container text-center">
      <Hello />
    </div>
    <hr />
  </div>
);

export default App;
