import React, { Component } from 'react';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

export default class MavicAir224mm12 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drone_name: null,
      drone_options: [
        { value: 'Inspire 2 (15mm)', label: 'Inspire 2 (15mm)' },
        { value: 'Inspire 2 (16mm)', label: 'Inspire 2 (16mm)' },
        { value: 'Inspire 2 (24mm)', label: 'Inspire 2 (24mm)' },
        { value: 'Inspire 2 (45mm)', label: 'Inspire 2 (45mm)' },
        { value: 'Mavic 2 Pro (19mm)', label: 'Mavic 2 Pro (19mm)' },
        { value: 'Mavic 2 Pro (28mm)', label: 'Mavic 2 Pro (28mm)' },
        { value: 'Mavic 2 Zoom (24mm)', label: 'Mavic 2 Zoom (24mm)' },
        { value: 'Mavic 2 Zoom (36mm)', label: 'Mavic 2 Zoom (36mm)' },
        { value: 'Mavic 2 Zoom (48mm)', label: 'Mavic 2 Zoom (48mm)' },
        { value: 'Mavic Mini (24mm)', label: 'Mavic Mini (24mm)' },
        { value: 'Phantom 4 Pro (24mm)', label: 'Phantom 4 Pro (24mm)' },
        { value: 'Falcon 8+ (35mm)', label: 'Falcon 8+ (35mm)' },
        { value: 'Trimble ZX5 (16mm)', label: 'Trimble ZX5 (16mm)' },
        { value: 'Matrice 600 Pro (55mm)', label: 'Matrice 600 Pro (55mm)' },
        { value: 'eBee X (S.O.D.A.) (29mm)', label: 'eBee X (S.O.D.A.) (29mm)' },
        { value: 'eBee X (Aeria X) (28mm)', label: 'eBee X (Aeria X) (28mm)' },
        { value: 'Sentaero v2VTOL (28mm)', label: 'Sentaero v2VTOL (28mm)' },
        { value: 'Sentaero v2VTOL (55mm)', label: 'Sentaero v2VTOL (55mm)' },
        { value: 'Bramor-ppX (35mm)', label: 'Bramor-ppX (35mm)' },
        { value: 'WingtraOne VTOL (35mm)', label: 'WingtraOne VTOL (35mm)' },
      ],
      front_overlay: '70',
      front_overlay_options: [
        { value: '30', label: '30' },
        { value: '40', label: '40' },
        { value: '50', label: '50' },
        { value: '60', label: '60' },
        { value: '66', label: '66' },
        { value: '70', label: '70' },
        { value: '75', label: '75' },
        { value: '80', label: '80' },
        { value: '90', label: '90' },
      ],
      side_overlay: '70',
      side_overlay_options: [
        { value: '30', label: '30' },
        { value: '40', label: '40' },
        { value: '50', label: '50' },
        { value: '60', label: '60' },
        { value: '66', label: '66' },
        { value: '70', label: '70' },
        { value: '75', label: '75' },
        { value: '80', label: '80' },
        { value: '90', label: '90' },
      ],
      format: 'JPG',
      format_options: [
        { value: 'RAW', label: 'RAW' },
        { value: 'JPG', label: 'JPG' },
      ],
      type: 'ROTOR',
      type_options: [
        { value: 'ROTOR', label: 'ROTOR' },
        { value: 'FIXED', label: 'FIXED' },
      ],
      camera: "DJI",
      fov_w: 71,
      fov_h: 53,
      focal_length: 4.5,
      w_px: 4000,
      h_px: 3000,
      w_mm: 6.4,
      h_mm: 4.8,
      mp: 12,
    }
    window.getState = () => this.state;
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {

    if (this.state.format == 'RAW' && this.state.type != 'FIXED') {
      this.state.ci_50 = 5;
      this.state.ci_100 = 5;
      this.state.ci_125 = 5;
      this.state.ci_150 = 5;
      this.state.ci_175 = 5;
      this.state.ci_200 = 5;
      this.state.ci_250 = 5;
      this.state.ci_300 = 5;
      this.state.ci_350 = 5;
      this.state.ci_400 = 5;
    } else if (this.state.format == 'JPG' && this.state.type != 'FIXED') {
      this.state.ci_50 = 3;
      this.state.ci_100 = 3;
      this.state.ci_125 = 3;
      this.state.ci_150 = 3;
      this.state.ci_175 = 3;
      this.state.ci_200 = 3;
      this.state.ci_250 = 3;
      this.state.ci_300 = 3;
      this.state.ci_350 = 3;
      this.state.ci_400 = 3;
    } else if (this.state.type == 'FIXED') {
      this.state.ci_50 = 0;
      this.state.ci_100 = 1;
      this.state.ci_125 = 1;
      this.state.ci_150 = 1;
      this.state.ci_175 = 1;
      this.state.ci_200 = 1;
      this.state.ci_250 = 1;
      this.state.ci_300 = 2;
      this.state.ci_350 = 2;
      this.state.ci_400 = 2;
    }

    var fov_w_100 = ((this.state.fov_w / 50.0) * 100.0).toFixed(0);
    var fov_h_100 = ((this.state.fov_h / 50.0) * 100.0).toFixed(0);
    // var neat_w_100 = fov_w_100 - (fov_w_100 * 0.3).toFixed(0);
    // var neat_h_100 = fov_h_100 - (fov_h_100 * 0.7).toFixed(0);


    var fov_w_125 = ((fov_w_100 / 100.0) * 125.0).toFixed(0);
    var fov_h_125 = ((fov_h_100 / 100.0) * 125.0).toFixed(0);
    // var neat_w_125 = fov_w_125 - (fov_w_125 * 0.7).toFixed(0);
    // var neat_h_125 = fov_h_125 - (fov_h_125 * 0.7).toFixed(0);


    var fov_w_150 = ((fov_w_125 / 125.0) * 150.0).toFixed(0);
    var fov_h_150 = ((fov_h_125 / 125.0) * 150.0).toFixed(0);
    // var neat_w_150 = fov_w_150 - (fov_w_150 * 0.7).toFixed(0);
    // var neat_h_150 = fov_h_150 - (fov_h_150 * 0.7).toFixed(0);


    var fov_w_175 = ((fov_w_150 / 150.0) * 175.0).toFixed(0);
    var fov_h_175 = ((fov_h_150 / 150.0) * 175.0).toFixed(0);
    // var neat_w_175 = fov_w_175 - (fov_w_175 * 0.7).toFixed(0);
    // var neat_h_175 = fov_h_175 - (fov_h_175 * 0.7).toFixed(0);


    var fov_w_200 = ((fov_w_175 / 175.0) * 200.0).toFixed(0);
    var fov_h_200 = ((fov_h_175 / 175.0) * 200.0).toFixed(0);
    // var neat_w_200 = fov_w_200 - (fov_w_200 * 0.7).toFixed(0);
    // var neat_h_200 = fov_h_200 - (fov_h_200 * 0.7).toFixed(0);


    var fov_w_250 = ((fov_w_200 / 200.0) * 250.0).toFixed(0);
    var fov_h_250 = ((fov_h_200 / 200.0) * 250.0).toFixed(0);
    // var neat_w_250 = fov_w_250 - (fov_w_250 * 0.7).toFixed(0);
    // var neat_h_250 = fov_h_250 - (fov_h_250 * 0.7).toFixed(0);


    var fov_w_300 = ((fov_w_250 / 250.0) * 300.0).toFixed(0);
    var fov_h_300 = ((fov_h_250 / 250.0) * 300.0).toFixed(0);
    // var neat_w_300 = fov_w_300 - (fov_w_300 * 0.7).toFixed(0);
    // var neat_h_300 = fov_h_300 - (fov_h_300 * 0.7).toFixed(0);


    var fov_w_350 = ((fov_w_300 / 300.0) * 350.0).toFixed(0);
    var fov_h_350 = ((fov_h_300 / 300.0) * 350.0).toFixed(0);
    // var neat_w_350 = fov_w_350 - (fov_w_350 * 0.7).toFixed(0);
    // var neat_h_350 = fov_h_350 - (fov_h_350 * 0.7).toFixed(0);


    var fov_w_400 = ((fov_w_350 / 350.0) * 400.0).toFixed(0);
    var fov_h_400 = ((fov_h_350 / 350.0) * 400.0).toFixed(0);
    // var neat_w_400 = fov_w_400 - (fov_w_400 * 0.7).toFixed(0);
    // var neat_h_400 = fov_h_400 - (fov_h_400 * 0.7).toFixed(0);


    if (this.state.front_overlay == "30") {
            var neat_w_50 = this.state.fov_w - (this.state.fov_w * 0.3).toFixed(0);
            var neat_w_100 = fov_w_100 - (fov_w_100 * 0.3).toFixed(0);
            var neat_w_125 = fov_w_125 - (fov_w_125 * 0.3).toFixed(0);
            var neat_w_150 = fov_w_150 - (fov_w_150 * 0.3).toFixed(0);
            var neat_w_175 = fov_w_175 - (fov_w_175 * 0.3).toFixed(0);
            var neat_w_200 = fov_w_200 - (fov_w_200 * 0.3).toFixed(0);
            var neat_w_250 = fov_w_250 - (fov_w_250 * 0.3).toFixed(0);
            var neat_w_300 = fov_w_300 - (fov_w_300 * 0.3).toFixed(0);
            var neat_w_350 = fov_w_350 - (fov_w_350 * 0.3).toFixed(0);
            var neat_w_400 = fov_w_400 - (fov_w_400 * 0.3).toFixed(0);
        } else if (this.state.front_overlay == "40") {
            var neat_w_50 = this.state.fov_w - (this.state.fov_w * 0.4).toFixed(0);
            var neat_w_100 = fov_w_100 - (fov_w_100 * 0.4).toFixed(0);
            var neat_w_125 = fov_w_125 - (fov_w_125 * 0.4).toFixed(0);
            var neat_w_150 = fov_w_150 - (fov_w_150 * 0.4).toFixed(0);
            var neat_w_175 = fov_w_175 - (fov_w_175 * 0.4).toFixed(0);
            var neat_w_200 = fov_w_200 - (fov_w_200 * 0.4).toFixed(0);
            var neat_w_250 = fov_w_250 - (fov_w_250 * 0.4).toFixed(0);
            var neat_w_300 = fov_w_300 - (fov_w_300 * 0.4).toFixed(0);
            var neat_w_350 = fov_w_350 - (fov_w_350 * 0.4).toFixed(0);
            var neat_w_400 = fov_w_400 - (fov_w_400 * 0.4).toFixed(0);
        } else if (this.state.front_overlay == "50") {
            var neat_w_50 = this.state.fov_w - (this.state.fov_w * 0.5).toFixed(0);
            var neat_w_100 = fov_w_100 - (fov_w_100 * 0.5).toFixed(0);
            var neat_w_125 = fov_w_125 - (fov_w_125 * 0.5).toFixed(0);
            var neat_w_150 = fov_w_150 - (fov_w_150 * 0.5).toFixed(0);
            var neat_w_175 = fov_w_175 - (fov_w_175 * 0.5).toFixed(0);
            var neat_w_200 = fov_w_200 - (fov_w_200 * 0.5).toFixed(0);
            var neat_w_250 = fov_w_250 - (fov_w_250 * 0.5).toFixed(0);
            var neat_w_300 = fov_w_300 - (fov_w_300 * 0.5).toFixed(0);
            var neat_w_350 = fov_w_350 - (fov_w_350 * 0.5).toFixed(0);
            var neat_w_400 = fov_w_400 - (fov_w_400 * 0.5).toFixed(0);
        } else if (this.state.front_overlay == "60") {
            var neat_w_50 = this.state.fov_w - (this.state.fov_w * 0.6).toFixed(0);
            var neat_w_100 = fov_w_100 - (fov_w_100 * 0.6).toFixed(0);
            var neat_w_125 = fov_w_125 - (fov_w_125 * 0.6).toFixed(0);
            var neat_w_150 = fov_w_150 - (fov_w_150 * 0.6).toFixed(0);
            var neat_w_175 = fov_w_175 - (fov_w_175 * 0.6).toFixed(0);
            var neat_w_200 = fov_w_200 - (fov_w_200 * 0.6).toFixed(0);
            var neat_w_250 = fov_w_250 - (fov_w_250 * 0.6).toFixed(0);
            var neat_w_300 = fov_w_300 - (fov_w_300 * 0.6).toFixed(0);
            var neat_w_350 = fov_w_350 - (fov_w_350 * 0.6).toFixed(0);
            var neat_w_400 = fov_w_400 - (fov_w_400 * 0.6).toFixed(0);
        } else if (this.state.front_overlay === "66") {
            var neat_w_50 = this.state.fov_w - (this.state.fov_w * 0.66).toFixed(0);
            var neat_w_100 = fov_w_100 - (fov_w_100 * 0.66).toFixed(0);
            var neat_w_125 = fov_w_125 - (fov_w_125 * 0.66).toFixed(0);
            var neat_w_150 = fov_w_150 - (fov_w_150 * 0.66).toFixed(0);
            var neat_w_175 = fov_w_175 - (fov_w_175 * 0.66).toFixed(0);
            var neat_w_200 = fov_w_200 - (fov_w_200 * 0.66).toFixed(0);
            var neat_w_250 = fov_w_250 - (fov_w_250 * 0.66).toFixed(0);
            var neat_w_300 = fov_w_300 - (fov_w_300 * 0.66).toFixed(0);
            var neat_w_350 = fov_w_350 - (fov_w_350 * 0.66).toFixed(0);
            var neat_w_400 = fov_w_400 - (fov_w_400 * 0.66).toFixed(0);
        } else if (this.state.front_overlay == "70") {
            var neat_w_50 = this.state.fov_w - (this.state.fov_w * 0.7).toFixed(0);
            var neat_w_100 = fov_w_100 - (fov_w_100 * 0.7).toFixed(0);
            var neat_w_125 = fov_w_125 - (fov_w_125 * 0.7).toFixed(0);
            var neat_w_150 = fov_w_150 - (fov_w_150 * 0.7).toFixed(0);
            var neat_w_175 = fov_w_175 - (fov_w_175 * 0.7).toFixed(0);
            var neat_w_200 = fov_w_200 - (fov_w_200 * 0.7).toFixed(0);
            var neat_w_250 = fov_w_250 - (fov_w_250 * 0.7).toFixed(0);
            var neat_w_300 = fov_w_300 - (fov_w_300 * 0.7).toFixed(0);
            var neat_w_350 = fov_w_350 - (fov_w_350 * 0.7).toFixed(0);
            var neat_w_400 = fov_w_400 - (fov_w_400 * 0.7).toFixed(0);
        } else if (this.state.front_overlay == "75") {
            var neat_w_50 = this.state.fov_w - (this.state.fov_w * 0.75).toFixed(0);
            var neat_w_100 = fov_w_100 - (fov_w_100 * 0.75).toFixed(0);
            var neat_w_125 = fov_w_125 - (fov_w_125 * 0.75).toFixed(0);
            var neat_w_150 = fov_w_150 - (fov_w_150 * 0.75).toFixed(0);
            var neat_w_175 = fov_w_175 - (fov_w_175 * 0.75).toFixed(0);
            var neat_w_200 = fov_w_200 - (fov_w_200 * 0.75).toFixed(0);
            var neat_w_250 = fov_w_250 - (fov_w_250 * 0.75).toFixed(0);
            var neat_w_300 = fov_w_300 - (fov_w_300 * 0.75).toFixed(0);
            var neat_w_350 = fov_w_350 - (fov_w_350 * 0.75).toFixed(0);
            var neat_w_400 = fov_w_400 - (fov_w_400 * 0.75).toFixed(0);
        } else if (this.state.front_overlay == "80") {
            var neat_w_50 = this.state.fov_w - (this.state.fov_w * 0.8).toFixed(0);
            var neat_w_100 = fov_w_100 - (fov_w_100 * 0.8).toFixed(0);
            var neat_w_125 = fov_w_125 - (fov_w_125 * 0.8).toFixed(0);
            var neat_w_150 = fov_w_150 - (fov_w_150 * 0.8).toFixed(0);
            var neat_w_175 = fov_w_175 - (fov_w_175 * 0.8).toFixed(0);
            var neat_w_200 = fov_w_200 - (fov_w_200 * 0.8).toFixed(0);
            var neat_w_250 = fov_w_250 - (fov_w_250 * 0.8).toFixed(0);
            var neat_w_300 = fov_w_300 - (fov_w_300 * 0.8).toFixed(0);
            var neat_w_350 = fov_w_350 - (fov_w_350 * 0.8).toFixed(0);
            var neat_w_400 = fov_w_400 - (fov_w_400 * 0.8).toFixed(0);
        } else if (this.state.front_overlay == "90") {
            var neat_w_50 = this.state.fov_w - (this.state.fov_w * 0.9).toFixed(0);
            var neat_w_100 = fov_w_100 - (fov_w_100 * 0.9).toFixed(0);
            var neat_w_125 = fov_w_125 - (fov_w_125 * 0.9).toFixed(0);
            var neat_w_150 = fov_w_150 - (fov_w_150 * 0.9).toFixed(0);
            var neat_w_175 = fov_w_175 - (fov_w_175 * 0.9).toFixed(0);
            var neat_w_200 = fov_w_200 - (fov_w_200 * 0.9).toFixed(0);
            var neat_w_250 = fov_w_250 - (fov_w_250 * 0.9).toFixed(0);
            var neat_w_300 = fov_w_300 - (fov_w_300 * 0.9).toFixed(0);
            var neat_w_350 = fov_w_350 - (fov_w_350 * 0.9).toFixed(0);
            var neat_w_400 = fov_w_400 - (fov_w_400 * 0.9).toFixed(0);
        };

        if (this.state.side_overlay == "30") {
            var neat_h_50 = this.state.fov_h - (this.state.fov_h * 0.3).toFixed(0);
            var neat_h_100 = fov_h_100 - (fov_h_100 * 0.3).toFixed(0);
            var neat_h_125 = fov_h_125 - (fov_h_125 * 0.3).toFixed(0);
            var neat_h_150 = fov_h_150 - (fov_h_150 * 0.3).toFixed(0);
            var neat_h_175 = fov_h_175 - (fov_h_175 * 0.3).toFixed(0);
            var neat_h_200 = fov_h_200 - (fov_h_200 * 0.3).toFixed(0);
            var neat_h_250 = fov_h_250 - (fov_h_250 * 0.3).toFixed(0);
            var neat_h_300 = fov_h_300 - (fov_h_300 * 0.3).toFixed(0);
            var neat_h_350 = fov_h_350 - (fov_h_350 * 0.3).toFixed(0);
            var neat_h_400 = fov_h_400 - (fov_h_400 * 0.3).toFixed(0);
        } else if (this.state.side_overlay == "40") {
            var neat_h_50 = this.state.fov_h - (this.state.fov_h * 0.4).toFixed(0);
            var neat_h_100 = fov_h_100 - (fov_h_100 * 0.4).toFixed(0);
            var neat_h_125 = fov_h_125 - (fov_h_125 * 0.4).toFixed(0);
            var neat_h_150 = fov_h_150 - (fov_h_150 * 0.4).toFixed(0);
            var neat_h_175 = fov_h_175 - (fov_h_175 * 0.4).toFixed(0);
            var neat_h_200 = fov_h_200 - (fov_h_200 * 0.4).toFixed(0);
            var neat_h_250 = fov_h_250 - (fov_h_250 * 0.4).toFixed(0);
            var neat_h_300 = fov_h_300 - (fov_h_300 * 0.4).toFixed(0);
            var neat_h_350 = fov_h_350 - (fov_h_350 * 0.4).toFixed(0);
            var neat_h_400 = fov_h_400 - (fov_h_400 * 0.4).toFixed(0);
        } else if (this.state.side_overlay == "50") {
            var neat_h_50 = this.state.fov_h - (this.state.fov_h * 0.5).toFixed(0);
            var neat_h_100 = fov_h_100 - (fov_h_100 * 0.5).toFixed(0);
            var neat_h_125 = fov_h_125 - (fov_h_125 * 0.5).toFixed(0);
            var neat_h_150 = fov_h_150 - (fov_h_150 * 0.5).toFixed(0);
            var neat_h_175 = fov_h_175 - (fov_h_175 * 0.5).toFixed(0);
            var neat_h_200 = fov_h_200 - (fov_h_200 * 0.5).toFixed(0);
            var neat_h_250 = fov_h_250 - (fov_h_250 * 0.5).toFixed(0);
            var neat_h_300 = fov_h_300 - (fov_h_300 * 0.5).toFixed(0);
            var neat_h_350 = fov_h_350 - (fov_h_350 * 0.5).toFixed(0);
            var neat_h_400 = fov_h_400 - (fov_h_400 * 0.5).toFixed(0);
        } else if (this.state.side_overlay == "66") {
            var neat_h_50 = this.state.fov_h - (this.state.fov_h * 0.66).toFixed(0);
            var neat_h_100 = fov_h_100 - (fov_h_100 * 0.66).toFixed(0);
            var neat_h_125 = fov_h_125 - (fov_h_125 * 0.66).toFixed(0);
            var neat_h_150 = fov_h_150 - (fov_h_150 * 0.66).toFixed(0);
            var neat_h_175 = fov_h_175 - (fov_h_175 * 0.66).toFixed(0);
            var neat_h_200 = fov_h_200 - (fov_h_200 * 0.66).toFixed(0);
            var neat_h_250 = fov_h_250 - (fov_h_250 * 0.66).toFixed(0);
            var neat_h_300 = fov_h_300 - (fov_h_300 * 0.66).toFixed(0);
            var neat_h_350 = fov_h_350 - (fov_h_350 * 0.66).toFixed(0);
            var neat_h_400 = fov_h_400 - (fov_h_400 * 0.66).toFixed(0);
        } else if (this.state.side_overlay == "60") {
            var neat_h_50 = this.state.fov_h - (this.state.fov_h * 0.6).toFixed(0);
            var neat_h_100 = fov_h_100 - (fov_h_100 * 0.6).toFixed(0);
            var neat_h_125 = fov_h_125 - (fov_h_125 * 0.6).toFixed(0);
            var neat_h_150 = fov_h_150 - (fov_h_150 * 0.6).toFixed(0);
            var neat_h_175 = fov_h_175 - (fov_h_175 * 0.6).toFixed(0);
            var neat_h_200 = fov_h_200 - (fov_h_200 * 0.6).toFixed(0);
            var neat_h_250 = fov_h_250 - (fov_h_250 * 0.6).toFixed(0);
            var neat_h_300 = fov_h_300 - (fov_h_300 * 0.6).toFixed(0);
            var neat_h_350 = fov_h_350 - (fov_h_350 * 0.6).toFixed(0);
            var neat_h_400 = fov_h_400 - (fov_h_400 * 0.6).toFixed(0);
        } else if (this.state.side_overlay == "70") {
            var neat_h_50 = this.state.fov_h - (this.state.fov_h * 0.7).toFixed(0);
            var neat_h_100 = fov_h_100 - (fov_h_100 * 0.7).toFixed(0);
            var neat_h_125 = fov_h_125 - (fov_h_125 * 0.7).toFixed(0);
            var neat_h_150 = fov_h_150 - (fov_h_150 * 0.7).toFixed(0);
            var neat_h_175 = fov_h_175 - (fov_h_175 * 0.7).toFixed(0);
            var neat_h_200 = fov_h_200 - (fov_h_200 * 0.7).toFixed(0);
            var neat_h_250 = fov_h_250 - (fov_h_250 * 0.7).toFixed(0);
            var neat_h_300 = fov_h_300 - (fov_h_300 * 0.7).toFixed(0);
            var neat_h_350 = fov_h_350 - (fov_h_350 * 0.7).toFixed(0);
            var neat_h_400 = fov_h_400 - (fov_h_400 * 0.7).toFixed(0);
        } else if (this.state.side_overlay == "75") {
            var neat_h_50 = this.state.fov_h - (this.state.fov_h * 0.75).toFixed(0);
            var neat_h_100 = fov_h_100 - (fov_h_100 * 0.75).toFixed(0);
            var neat_h_125 = fov_h_125 - (fov_h_125 * 0.75).toFixed(0);
            var neat_h_150 = fov_h_150 - (fov_h_150 * 0.75).toFixed(0);
            var neat_h_175 = fov_h_175 - (fov_h_175 * 0.75).toFixed(0);
            var neat_h_200 = fov_h_200 - (fov_h_200 * 0.75).toFixed(0);
            var neat_h_250 = fov_h_250 - (fov_h_250 * 0.75).toFixed(0);
            var neat_h_300 = fov_h_300 - (fov_h_300 * 0.75).toFixed(0);
            var neat_h_350 = fov_h_350 - (fov_h_350 * 0.75).toFixed(0);
            var neat_h_400 = fov_h_400 - (fov_h_400 * 0.75).toFixed(0);
        } else if (this.state.side_overlay == "80") {
            var neat_h_50 = this.state.fov_h - (this.state.fov_h * 0.8).toFixed(0);
            var neat_h_100 = fov_h_100 - (fov_h_100 * 0.8).toFixed(0);
            var neat_h_125 = fov_h_125 - (fov_h_125 * 0.8).toFixed(0);
            var neat_h_150 = fov_h_150 - (fov_h_150 * 0.8).toFixed(0);
            var neat_h_175 = fov_h_175 - (fov_h_175 * 0.8).toFixed(0);
            var neat_h_200 = fov_h_200 - (fov_h_200 * 0.8).toFixed(0);
            var neat_h_250 = fov_h_250 - (fov_h_250 * 0.8).toFixed(0);
            var neat_h_300 = fov_h_300 - (fov_h_300 * 0.8).toFixed(0);
            var neat_h_350 = fov_h_350 - (fov_h_350 * 0.8).toFixed(0);
            var neat_h_400 = fov_h_400 - (fov_h_400 * 0.8).toFixed(0);
        } else if (this.state.side_overlay == "90") {
            var neat_h_50 = this.state.fov_h - (this.state.fov_h * 0.9).toFixed(0);
            var neat_h_100 = fov_h_100 - (fov_h_100 * 0.9).toFixed(0);
            var neat_h_125 = fov_h_125 - (fov_h_125 * 0.9).toFixed(0);
            var neat_h_150 = fov_h_150 - (fov_h_150 * 0.9).toFixed(0);
            var neat_h_175 = fov_h_175 - (fov_h_175 * 0.9).toFixed(0);
            var neat_h_200 = fov_h_200 - (fov_h_200 * 0.9).toFixed(0);
            var neat_h_250 = fov_h_250 - (fov_h_250 * 0.9).toFixed(0);
            var neat_h_300 = fov_h_300 - (fov_h_300 * 0.9).toFixed(0);
            var neat_h_350 = fov_h_350 - (fov_h_350 * 0.9).toFixed(0);
            var neat_h_400 = fov_h_400 - (fov_h_400 * 0.9).toFixed(0);
        };

    if (this.state.type == "FIXED") {
      var speed_50 = 40;
      // this is to properly set the CI for FIXED type
      this.state.ci_50 = (neat_h_50 / (40 * 5280) * 3600).toFixed(1);
      this.state.ci_100 = (neat_h_100 / (40 * 5280) * 3600).toFixed(1);
      this.state.ci_125 = (neat_h_125 / (40 * 5280) * 3600).toFixed(1);
      this.state.ci_150 = (neat_h_150 / (40 * 5280) * 3600).toFixed(1);
      this.state.ci_175 = (neat_h_175 / (40 * 5280) * 3600).toFixed(1);
      this.state.ci_200 = (neat_h_200 / (40 * 5280) * 3600).toFixed(1);
      this.state.ci_250 = (neat_h_250 / (40 * 5280) * 3600).toFixed(1);
      this.state.ci_300 = (neat_h_300 / (40 * 5280) * 3600).toFixed(1);
      this.state.ci_350 = (neat_h_350 / (40 * 5280) * 3600).toFixed(1);
      this.state.ci_400 = (neat_h_400 / (40 * 5280) * 3600).toFixed(1);
    } else if (this.state.type == "ROTOR" && this.state.format == "RAW") {
      var speed_50 = ((neat_h_50 * 3600 / 5) / 5280.0).toFixed(1);
    } else if (this.state.type == "ROTOR" && this.state.format == "JPG") {
      var speed_50 = ((neat_h_50 * 3600 / 3) / 5280.0).toFixed(1);
    };
    var gsd_50 = ((this.state.fov_w / this.state.w_px) * 30.48).toFixed(2);
    var img_per_acre_50 = (43560.0 / (neat_w_50 * neat_h_50)).toFixed(0);
    var flight_time_50 = (this.state.ci_50 * img_per_acre_50 / 60.00).toFixed(2);

    if (this.state.type == "FIXED") {
      var speed_100 = 40;
      // if fixed, divide by 40
    } else if (this.state.type == "ROTOR" && this.state.format == "RAW") {
      var speed_100 = ((neat_h_100 * 3600 / 5) / 5280.0).toFixed(1);
    } else if (this.state.type == "ROTOR" && this.state.format == "JPG") {
      var speed_100 = ((neat_h_100 * 3600 / 3) / 5280.0).toFixed(1);
    };
    var gsd_100 = ((fov_w_100 / this.state.w_px) * 30.48).toFixed(2);
    var img_per_acre_100 = (43560.0 / (neat_w_100 * neat_h_100)).toFixed(0);
    var flight_time_100 = (this.state.ci_100 * img_per_acre_100 / 60.00).toFixed(2);

    if (this.state.type == "FIXED") {
      var speed_125 = 40;
    } else if (this.state.type == "ROTOR" && this.state.format ==
      "RAW") {
      var speed_125 = ((neat_h_125 * 3600 / 5) / 5280.0).toFixed(1);
    } else if (this.state.type == "ROTOR" && this.state.format == "JPG") {
      var speed_125 = ((neat_h_125 * 3600 / 3) / 5280.0).toFixed(1);
    };
    var gsd_125 = ((fov_w_125 / this.state.w_px) * 30.48).toFixed(2);
    var img_per_acre_125 = (43560.0 / (neat_w_125 * neat_h_125)).toFixed(0);
    var flight_time_125 = (this.state.ci_125 * img_per_acre_125 / 60.00).toFixed(2);

    if (this.state.type == "FIXED") {
      var speed_150 = 40;
    } else if (this.state.type == "ROTOR" && this.state.format == "RAW") {
      var speed_150 = ((neat_h_150 * 3600 / 5) / 5280.0).toFixed(1);
    } else if (this.state.type == "ROTOR" && this.state.format == "JPG") {
      var speed_150 = ((neat_h_150 * 3600 / 3) / 5280.0).toFixed(1);
    };
    var gsd_150 = ((fov_w_150 / this.state.w_px) * 30.48).toFixed(2);
    var img_per_acre_150 = (43560.0 / (neat_w_150 * neat_h_150)).toFixed(0);
    var flight_time_150 = (this.state.ci_150 * img_per_acre_150 / 60.00).toFixed(2);

    if (this.state.type == "FIXED") {
      var speed_175 = 40;
    } else if (this.state.type == "ROTOR" && this.state.format == "RAW") {
      var speed_175 = ((neat_h_175 * 3600 / 5) / 5280.0).toFixed(1);
    } else if (this.state.type == "ROTOR" && this.state.format == "JPG") {
      var speed_175 = ((neat_h_175 * 3600 / 3) / 5280.0).toFixed(1);
    };
    var gsd_175 = ((fov_w_175 / this.state.w_px) * 30.48).toFixed(2);
    var img_per_acre_175 = (43560.0 / (neat_w_175 * neat_h_175)).toFixed(0);
    var flight_time_175 = (this.state.ci_175 * img_per_acre_175 / 60.00).toFixed(2);

    if (this.state.type == "FIXED") {
      var speed_200 = 40;
    } else if (this.state.type == "ROTOR" && this.state.format == "RAW") {
      var speed_200 = ((neat_h_200 * 3600 / 5) / 5280.0).toFixed(1);
    } else if (this.state.type == "ROTOR" && this.state.format == "JPG") {
      var speed_200 = ((neat_h_200 * 3600 / 3) / 5280.0).toFixed(1);
    };
    var gsd_200 = ((fov_w_200 / this.state.w_px) * 30.48).toFixed(2);
    var img_per_acre_200 = (43560.0 / (neat_w_200 * neat_h_200)).toFixed(0);
    var flight_time_200 = (this.state.ci_200 * img_per_acre_200 / 60.00).toFixed(2);

    if (this.state.type == "FIXED") {
      var speed_250 = 40;
    } else if (this.state.type == "ROTOR" && this.state.format == "RAW") {
      var speed_250 = ((neat_h_250 * 3600 / 5) / 5280.0).toFixed(1);
    } else if (this.state.type == "ROTOR" && this.state.format == "JPG") {
      var speed_250 = ((neat_h_250 * 3600 / 3) / 5280.0).toFixed(1);
    };
    var gsd_250 = ((fov_w_250 / this.state.w_px) * 30.48).toFixed(2);
    var img_per_acre_250 = (43560.0 / (neat_w_250 * neat_h_250)).toFixed(0);
    var flight_time_250 = (this.state.ci_250 * img_per_acre_250 / 60.00).toFixed(2);

    if (this.state.type == "FIXED") {
      var speed_300 = 40;
    } else if (this.state.type == "ROTOR" && this.state.format == "RAW") {
      var speed_300 = ((neat_h_300 * 3600 / 5) / 5280.0).toFixed(1);
    } else if (this.state.type == "ROTOR" && this.state.format == "JPG") {
      var speed_300 = ((neat_h_300 * 3600 / 3) / 5280.0).toFixed(1);
    };
    var gsd_300 = ((fov_w_300 / this.state.w_px) * 30.48).toFixed(2);
    var img_per_acre_300 = (43560.0 / (neat_w_300 * neat_h_300)).toFixed(0);
    var flight_time_300 = (this.state.ci_300 * img_per_acre_300 / 60.00).toFixed(2);

    if (this.state.type == "FIXED") {
      var speed_350 = 40;
    } else if (this.state.type == "ROTOR" && this.state.format == "RAW") {
      var speed_350 = ((neat_h_350 * 3600 / 5) / 5280.0).toFixed(1);
    } else if (this.state.type == "ROTOR" && this.state.format == "JPG") {
      var speed_350 = ((neat_h_350 * 3600 / 3) / 5280.0).toFixed(1);
    };
    var gsd_350 = ((fov_w_350 / this.state.w_px) * 30.48).toFixed(2);
    var img_per_acre_350 = (43560.0 / (neat_w_350 * neat_h_350)).toFixed(0);
    var flight_time_350 = (this.state.ci_350 * img_per_acre_350 / 60.00).toFixed(2);

    if (this.state.type == "FIXED") {
      var speed_400 = 40;
    } else if (this.state.type == "ROTOR" && this.state.format == "RAW") {
      var speed_400 = ((neat_h_400 * 3600 / 5) / 5280.0).toFixed(1);
    } else if (this.state.type == "ROTOR" && this.state.format == "JPG") {
      var speed_400 = ((neat_h_400 * 3600 / 3) / 5280.0).toFixed(1);
    };
    var gsd_400 = ((fov_w_400 / this.state.w_px) * 30.48).toFixed(2);
    var img_per_acre_400 = (43560.0 / (neat_w_400 * neat_h_400)).toFixed(0);
    var flight_time_400 = (this.state.ci_400 * img_per_acre_400 / 60.00).toFixed(2);

    return (
      <div className="table-responsive">

        <div className="form-row">
          <div class="col-md-4">
            <label>Front Overlap %</label>
            <Select
              placeholder="Select Side Overlay"
              name="side_overlay"
              value={this.state.side_overlay}
              onChange={this.handleSelectChange("side_overlay")}
              options={this.state.side_overlay_options}
            />
          </div>
          <div class="col-md-4">
            <label>Side Overlap %</label>
            <Select
              placeholder="Select Front Overlay"
              name="front_overlay"
              value={this.state.front_overlay}
              onChange={this.handleSelectChange("front_overlay")}
              options={this.state.front_overlay_options}
            />
          </div>
          <div class="col-md-2">
            <label>Format</label>
            <Select
              placeholder="Select Format"
              name="format"
              value={this.state.format}
              onChange={this.handleSelectChange("format")}
              options={this.state.format_options}
            />
          </div>
          <div class="col-md-2">
            <label>Type</label>
            <Select
              placeholder="Select Type"
              name="type"
              value={this.state.type}
              onChange={this.handleSelectChange("type")}
              options={this.state.type_options}
            />
          </div>
        </div>
        <br />
        <table className="table table-sm table-bordered">
          <thead>
            <th>Camera/Image/Lens/Sensor Meta Data</th>
          </thead>
          <tbody>
            <tr>
              <td>
                {this.state.camera} {this.state.mp} mp {this.state.w_px} x {this.state.h_px} (px)<br />
                <hr />
                                Focal Length {this.state.focal_length} (mm); Sensor {this.state.w_mm} x {this.state.h_mm} (mm)
                            </td>
            </tr>
          </tbody>
        </table>
        <table className="table table-sm table-bordered table-hover">
          <thead>
            <th>AGL (FT)</th>
            <th>FOV (w)</th>
            <th>FOV (h)</th>
            <th>Neat (w)</th>
            <th>Neat (h)</th>
            <th>Speed (mph)</th>
            <th>Capture Interval (sec)</th>
            <th>GSD (cm)</th>
            <th>Approx. # images per Acre</th>
            <th>Approx. Flight Time (mins)</th>
          </thead>
          <tbody>
            <tr>
              <td style={{ backgroundColor: 'lightgrey' }}>50</td>
              <td>{this.state.fov_w}</td>
              <td>{this.state.fov_h}</td>
              <td className="neat-bg">{neat_w_50}</td>
              <td>{neat_h_50}</td>
              <td className="speed-bg">{speed_50}</td>
              <td className="ci-bg">{this.state.ci_50}</td>
              <td className="gsd-bg">{gsd_50}</td>
              <td>{img_per_acre_50}</td>
              <td>{flight_time_50}</td>
            </tr>
            <tr>
              <td style={{ backgroundColor: 'lightgrey' }}>100</td>
              <td>{fov_w_100}</td>
              <td>{fov_h_100}</td>
              <td className="neat-bg">{neat_w_100}</td>
              <td>{neat_h_100}</td>
              <td className="speed-bg">{speed_100}</td>
              <td className="ci-bg">{this.state.ci_100}</td>
              <td className="gsd-bg">{gsd_100}</td>
              <td>{img_per_acre_100}</td>
              <td>{flight_time_100}</td>
            </tr>
            <tr>
              <td style={{ backgroundColor: 'lightgrey' }}>125</td>
              <td>{fov_w_125}</td>
              <td>{fov_h_125}</td>
              <td className="neat-bg">{neat_w_125}</td>
              <td>{neat_h_125}</td>
              <td className="speed-bg">{speed_125}</td>
              <td className="ci-bg">{this.state.ci_125}</td>
              <td className="gsd-bg">{gsd_125}</td>
              <td>{img_per_acre_125}</td>
              <td>{flight_time_125}</td>
            </tr>
            <tr>
              <td style={{ backgroundColor: 'lightgrey' }}>150</td>
              <td>{fov_w_150}</td>
              <td>{fov_h_150}</td>
              <td className="neat-bg">{neat_w_150}</td>
              <td>{neat_h_150}</td>
              <td className="speed-bg">{speed_150}</td>
              <td className="ci-bg">{this.state.ci_150}</td>
              <td className="gsd-bg">{gsd_150}</td>
              <td>{img_per_acre_150}</td>
              <td>{flight_time_150}</td>
            </tr>
            <tr>
              <td style={{ backgroundColor: 'lightgrey' }}>175</td>
              <td>{fov_w_175}</td>
              <td>{fov_h_175}</td>
              <td className="neat-bg">{neat_w_175}</td>
              <td>{neat_h_175}</td>
              <td className="speed-bg">{speed_175}</td>
              <td className="ci-bg">{this.state.ci_175}</td>
              <td className="gsd-bg">{gsd_175}</td>
              <td>{img_per_acre_175}</td>
              <td>{flight_time_175}</td>
            </tr>
            <tr>
              <td style={{ backgroundColor: 'lightgrey' }}>200</td>
              <td>{fov_w_200}</td>
              <td>{fov_h_200}</td>
              <td className="neat-bg">{neat_w_200}</td>
              <td>{neat_h_200}</td>
              <td className="speed-bg">{speed_200}</td>
              <td className="ci-bg">{this.state.ci_200}</td>
              <td className="gsd-bg">{gsd_200}</td>
              <td>{img_per_acre_200}</td>
              <td>{flight_time_200}</td>
            </tr>
            <tr>
              <td style={{ backgroundColor: 'lightgrey' }}>250</td>
              <td>{fov_w_250}</td>
              <td>{fov_h_250}</td>
              <td className="neat-bg">{neat_w_250}</td>
              <td>{neat_h_250}</td>
              <td className="speed-bg">{speed_250}</td>
              <td className="ci-bg">{this.state.ci_250}</td>
              <td className="gsd-bg">{gsd_250}</td>
              <td>{img_per_acre_250}</td>
              <td>{flight_time_250}</td>
            </tr>
            <tr>
              <td style={{ backgroundColor: 'lightgrey' }}>300</td>
              <td>{fov_w_300}</td>
              <td>{fov_h_300}</td>
              <td className="neat-bg">{neat_w_300}</td>
              <td>{neat_h_300}</td>
              <td className="speed-bg">{speed_300}</td>
              <td className="ci-bg">{this.state.ci_300}</td>
              <td className="gsd-bg">{gsd_300}</td>
              <td>{img_per_acre_300}</td>
              <td>{flight_time_300}</td>
            </tr>
            <tr>
              <td style={{ backgroundColor: 'lightgrey' }}>350</td>
              <td>{fov_w_350}</td>
              <td>{fov_h_350}</td>
              <td className="neat-bg">{neat_w_350}</td>
              <td>{neat_h_350}</td>
              <td className="speed-bg">{speed_350}</td>
              <td className="ci-bg">{this.state.ci_350}</td>
              <td className="gsd-bg">{gsd_350}</td>
              <td>{img_per_acre_350}</td>
              <td>{flight_time_350}</td>
            </tr>
            <tr>
              <td style={{ backgroundColor: 'lightgrey' }}>400</td>
              <td>{fov_w_400}</td>
              <td>{fov_h_400}</td>
              <td className="neat-bg">{neat_w_400}</td>
              <td>{neat_h_400}</td>
              <td className="speed-bg">{speed_400}</td>
              <td className="ci-bg">{this.state.ci_400}</td>
              <td className="gsd-bg">{gsd_400}</td>
              <td>{img_per_acre_400}</td>
              <td>{flight_time_400}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}