import React, { Component } from 'react';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import Inspire15mm from './Inspire15mm.jsx';
import Inspire16mm from './Inspire16mm.jsx';
import Inspire24mm from './Inspire24mm.jsx';
import Inspire45mm from './Inspire45mm.jsx';
import Mavic2Pro19mm from './Mavic2Pro19mm.jsx';
import Mavic2Pro28mm from './Mavic2Pro28mm.jsx';
import Mavic2Zoom24mm from './Mavic2Zoom24mm.jsx';
import Mavic2Zoom36mm from './Mavic2Zoom36mm.jsx';
import Mavic2Zoom48mm from './Mavic2Zoom48mm.jsx';
import MavicMini24mm from './MavicMini24mm.jsx';
import Phantom4Pro24mm from './Phantom4Pro24mm.jsx';
import Falcon35mm from './Falcon35mm.jsx';
import Trimble16mm from './Trimble16mm.jsx';
import Matrice35024mm from "./Matrice35024mm.jsx";
import MatricePro55mm from './MatricePro55mm.jsx';
import MatricePro80mm from './MatricePro80mm.jsx';
import Soda29mm from './Soda29mm.jsx';
import Aeria28mm from './Aeria28mm.jsx';
import Sentaero28mm from './Sentaero28mm.jsx';
import Sentaero55mm from './Sentaero55mm.jsx';
import Bramor35mm from './Bramor35mm.jsx';
import WingtraOne35mm from './WingtraOne35mm.jsx';
import MavicAir224mm12 from './MavicAir224mm12.jsx';
import MavicAir224mm48 from './MavicAir224mm48.jsx';
import Matrice300P124mm from './Matrice300P124mm.jsx';
import Matrice300P135mm from './Matrice300P135mm.jsx';
import Matrice300L124mm from './Matrice300L124mm.jsx';
import Mavic3Enterprise24mm from './Mavic3Enterprise24mm';


export default class Hello extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drone_name: null,
      drone_options: [
        { value: "Inspire 2 (15mm)", label: "Inspire 2 (15mm)" },
        { value: "Inspire 2 (16mm)", label: "Inspire 2 (16mm)" },
        { value: "Inspire 2 (24mm)", label: "Inspire 2 (24mm)" },
        { value: "Inspire 2 (45mm)", label: "Inspire 2 (45mm)" },
        { value: "Mavic 2 Pro (19mm)", label: "Mavic 2 Pro (19mm)" },
        { value: "Mavic 2 Pro (28mm)", label: "Mavic 2 Pro (28mm)" },
        { value: "Mavic 2 Zoom (24mm)", label: "Mavic 2 Zoom (24mm)" },
        { value: "Mavic 2 Zoom (36mm)", label: "Mavic 2 Zoom (36mm)" },
        { value: "Mavic 2 Zoom (48mm)", label: "Mavic 2 Zoom (48mm)" },
        { value: "Mavic 3 Enterprise (24mm)", label: "Mavic 3 Enterprise (24mm)" },
        { value: "Mavic Air 2 (24mm) - 12", label: "Mavic Air 2 (24mm) - 12" },
        { value: "Mavic Air 2 (24mm) - 48", label: "Mavic Air 2 (24mm) - 48" },
        { value: "Mavic Mini (24mm)", label: "Mavic Mini (24mm)" },
        { value: "Phantom 4 Pro (24mm)", label: "Phantom 4 Pro (24mm)" },
        { value: "Falcon 8+ (35mm)", label: "Falcon 8+ (35mm)" },
        { value: "Trimble ZX5 (16mm)", label: "Trimble ZX5 (16mm)" },
        { value: "Matrice 300 (P1 24mm)", label: "Matrice 300 (P1 24mm)" },
        { value: "Matrice 300 (L1 24mm)", label: "Matrice 300 (L1 24mm)" },
        { value: "Matrice 300 (P1 35mm)", label: "Matrice 300 (P1 35mm)" },
        { value: "Matrice 350 (L2 24mm)", label: "Matrice 350 (L2 24mm)" },
        { value: "Matrice 600 Pro (55mm)", label: "Matrice 600 Pro (55mm)" },
        { value: "Matrice 600 Pro (80mm)", label: "Matrice 600 Pro (80mm)" },
        { value: "eBee X (S.O.D.A.) (29mm)", label: "eBee X (S.O.D.A.) (29mm)" },
        { value: "eBee X (Aeria X) (28mm)", label: "eBee X (Aeria X) (28mm)" },
        { value: "Sentaero v2VTOL (28mm)", label: "Sentaero v2VTOL (28mm)" },
        { value: "Sentaero v2VTOL (55mm)", label: "Sentaero v2VTOL (55mm)" },
        { value: "Bramor-ppX (35mm)", label: "Bramor-ppX (35mm)" },
        { value: "WingtraOne VTOL (35mm)", label: "WingtraOne VTOL (35mm)" },
      ],
    };
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {

    return (
      <div>
        <div className="form-row text-center">
          <div class="col-md-4">
            <label>Drone Name</label>
            <Select
              placeholder="Select Drone"
              name="drone_name"
              value={this.state.drone_name}
              onChange={this.handleSelectChange("drone_name")}
              options={this.state.drone_options}
            />
          </div>
        </div>
        <br />
        {this.state.drone_name == "Inspire 2 (15mm)" && <Inspire15mm />}
        {this.state.drone_name == "Inspire 2 (16mm)" && <Inspire16mm />}
        {this.state.drone_name == "Inspire 2 (24mm)" && <Inspire24mm />}
        {this.state.drone_name == "Inspire 2 (45mm)" && <Inspire45mm />}
        {this.state.drone_name == "Mavic 2 Pro (19mm)" && <Mavic2Pro19mm />}
        {this.state.drone_name == "Mavic 2 Pro (28mm)" && <Mavic2Pro28mm />}
        {this.state.drone_name == "Mavic 2 Zoom (24mm)" && <Mavic2Zoom24mm />}
        {this.state.drone_name == "Mavic 2 Zoom (36mm)" && <Mavic2Zoom36mm />}
        {this.state.drone_name == "Mavic 2 Zoom (48mm)" && <Mavic2Zoom48mm />}
        {this.state.drone_name == "Mavic Mini (24mm)" && <MavicMini24mm />}
        {this.state.drone_name == "Phantom 4 Pro (24mm)" && <Phantom4Pro24mm />}
        {this.state.drone_name == "Falcon 8+ (35mm)" && <Falcon35mm />}
        {this.state.drone_name == "Trimble ZX5 (16mm)" && <Trimble16mm />}
        {this.state.drone_name == "Matrice 350 (L2 24mm)" && (
          <Matrice35024mm />
        )}
        {this.state.drone_name == "Matrice 600 Pro (55mm)" && (
          <MatricePro55mm />
        )}
        {this.state.drone_name == "Matrice 600 Pro (80mm)" && (
          <MatricePro80mm />
        )}
        {this.state.drone_name == "eBee X (S.O.D.A.) (29mm)" && <Soda29mm />}
        {this.state.drone_name == "eBee X (Aeria X) (28mm)" && <Aeria28mm />}
        {this.state.drone_name == "Sentaero v2VTOL (28mm)" && <Sentaero28mm />}
        {this.state.drone_name == "Sentaero v2VTOL (55mm)" && <Sentaero55mm />}
        {this.state.drone_name == "Bramor-ppX (35mm)" && <Bramor35mm />}
        {this.state.drone_name == "WingtraOne VTOL (35mm)" && (
          <WingtraOne35mm />
        )}
        {this.state.drone_name == "Mavic Air 2 (24mm) - 12" && (
          <MavicAir224mm12 />
        )}
        {this.state.drone_name == "Mavic Air 2 (24mm) - 48" && (
          <MavicAir224mm48 />
        )}
        {this.state.drone_name == "Matrice 300 (P1 24mm)" && (
          <Matrice300P124mm />
        )}
        {this.state.drone_name == "Matrice 300 (L1 24mm)" && (
          <Matrice300L124mm />
        )}
        {this.state.drone_name == "Matrice 300 (P1 35mm)" && (
          <Matrice300P135mm />
        )}
        {this.state.drone_name == "Mavic 3 Enterprise (24mm)" && (
          <Mavic3Enterprise24mm />
        )}
      </div>
    );
  }
}
