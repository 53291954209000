import React from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';

const Menu = () => {

    return (
        <div>
            <Navbar color="faded" light>
                <NavbarBrand href="/" className="mr-auto">
                    Survey Mapping Made Simple - Flight Parameters Web App
                </NavbarBrand>
            </Navbar>
        </div>
    );
}

export default Menu;
